import jQuery from "jquery"
import noPhoto from '../images/nophoto.png';
import mapPinGrey from '../i/map-pin-grey.svg';
import mapPinDarkGrey from '../i/map-pin-dark-grey.svg';

document.$ = jQuery
window.$ = jQuery
document.jQuery = jQuery
window.jQuery = jQuery

import './all.js'

$(document).ready(function($) {

  $("img").on('error', function(){
     $(this).attr( "src", noPhoto );
  });

	// var rangeSliderWidth;

	// svg4everybody();

	$('.open-feedback-modal').magnificPopup({
		mainClass: 'mfp-fade',
		removalDelay: 300,
		preloader: false,
		fixedBgPos: true,
		fixedContentPos: true,
	});

  initTabs();

	// if ($(window).width() > 1190) {
	// 	rangeSliderWidth = 570
	// } else {
	// 	rangeSliderWidth = $(window).width()/2 - 50
	// }

	// $('.range-slider:not(._short)').jRange({
	// 	from: 100000,
	// 	to: 100000000,
	// 	step: 1000,
	// 	format: '%s $',
	// 	showLabels: true,
	// 	width: rangeSliderWidth,
	// 	onstatechange: function(val){
	// 		$('.rangeValue').val(val);
	// 	}
	// });

	// $('.range-slider._short').jRange({
	// 	from: 100000,
	// 	to: 100000000,
	// 	step: 1000,
	// 	format: '%s $',
	// 	showLabels: true,
	// 	width: 300,
	// 	onstatechange: function(val){
	// 		$('.rangeValue').val(val);
	// 	}
	// });

	// $('.ui.accordion').accordion();

  // show full listing info

  $('.read-full-info').click(function() {
    $('.full-info-content').css({
      'opacity': '1',
      'visibility': 'visible',
      'max-height': '50000000px'
    });
    $(this).parent().css({
      'opacity': '0',
      'visibility': 'hidden',
      'transform': 'translateY(-10%)',
      '-webkit-transform': 'translateY(-10%)',
      'max-height': '0'
    })
    return false;
  });

  $(document).on('click', '.popup-modal-dismiss', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
    $.post($('#feedbackTextarea').data('url'),
      {
        feedback: $('#feedbackTextarea').val()
      }
    );
  });

});

window.initMap = function() {
  console.log('INIT MAP');

  window.__mapJsLoaded = true;
  
  if($('.variant-tab').length){
    initSample();
  };
}

function getMapOptionsFromData(selector) {
  let $map = $(selector)
  if($map.length === 1){
    let options = {};
    if($map.data().centerCoordinate){
      options.center = new google.maps.LatLng(parseFloat($map.data().centerCoordinate.lat),
                                              parseFloat($map.data().centerCoordinate.lng));
    }
    return options;
  } else{
    return {};
  }
}

function initSample(){
  $('.fotorama-outer').fotorama({
    click: false,
    swipe: false,
    width: '100%',
    height: 240,
    nav: false
  });
}

function show_sample($section, id){
  var $fotoramaDiv = $section.find('.fotorama-outer');
  var fotorama = $fotoramaDiv.data('fotorama');
  fotorama.show(id);
}

function renderSampleMap($section, sampleMapMarkers){
  var $sample_map_wrapper = $section.find('.sample-map');
  var sample_map_latlng = new google.maps.LatLng(sampleMapMarkers[0].lat, sampleMapMarkers[0].lng);
  var sampleMapOptions = {mapTypeControl: false, zoom: 10, center: sample_map_latlng};
  var sample_map = new google.maps.Map($sample_map_wrapper[0], sampleMapOptions);

  var icon = {
    url: mapPinGrey,
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0,0),
    anchor: new google.maps.Point(20, 20)
  };

  var originalIcon = {
    url: mapPinGrey,
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0,0),
    anchor: new google.maps.Point(20, 20)
  };

  var hoverIcon = {
    url: mapPinDarkGrey,
    scaledSize: new google.maps.Size(40, 40),
    origin: new google.maps.Point(0,0),
    anchor: new google.maps.Point(20, 20)
  };

  $.each(sampleMapMarkers, function(key, val) {
    var marker = new google.maps.Marker({
      map: sample_map,
      position: new google.maps.LatLng(val.lat, val.lng),
      title: val.name,
      icon: icon,
      hoverIcon: hoverIcon,
      originalIcon: originalIcon
    });

    google.maps.event.addListener(marker, "mouseover", function() {
      this.setIcon(this.hoverIcon);
      show_sample($section, key);
    });

    google.maps.event.addListener(marker, "mouseout", function() {
      this.setIcon(this.originalIcon);
      show_sample($section, key);
    });

  });
}

function initTabs() {

  $.each($('.tabs'), function (key, val) {
    var tabs = $(this);
    var tabLink = $(this).find('a');

    $(this).find('li a:not(:first)').addClass('inactive');
    $(this).next().find('.tab-content').hide();
    $(this).next().find('.tab-content:first').show();

    tabLink.click(function () {
      var tabLinkHref = $(this).attr('href');
      if ($(this).hasClass('inactive')) {
        tabLink.addClass('inactive');
        $(this).removeClass('inactive');
        tabs.next().find('.tab-content').hide();
        $(tabLinkHref).fadeIn('fast');
      }
      return false;
    });
  })
}